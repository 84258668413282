import React from 'react';
import {Box, Container, Typography} from '@mui/material';
import {MailOutlined} from "@mui/icons-material";

function Support() {

  const _launchMail = () => {
    window.location.href = "mailto:service@terrago.ai";
  };

  return (
    <Container maxWidth="lg" sx={{ padding: 2 }}>
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        歡迎使用 TerraGo 客戶支援
      </Typography>

      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        需要服務或支援？請來信提出請求，我們將為你尋找解決方案。
      </Typography>
      <Box sx={{ height: 10 }} />
      <Box display={'flex'} alignItems={'center'} mt={6}>
        <MailOutlined size={40} className="inline text-bermuda"/>
        <Typography href="#" onClick={_launchMail} sx={{color: 'blue', textDecoration: 'underline', fontSize: 16}}>
          service@terrago.ai
        </Typography>
      </Box>
      <Box sx={{ height: 70 }} />
    </Container>
  );
}

export default Support;
