import React from 'react';
import {Box, Container, Typography} from '@mui/material';

function PrivacyPolicy() {

  return (
    <Container maxWidth="lg" sx={{ padding: 2 }}>
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        我們非常重視您的隱私，並致力於保護您在使用「TerraGo」App時提供的個人資料。本隱私權政策將詳細說明我們如何蒐集、使用、分享和保護您的個人資料。請務必詳細閱讀，並在使用我們的服務前確認您已了解並同意本隱私權政策的內容。
      </Typography>
      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        資料蒐集與使用
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        我們可能會蒐集您在使用本應用程式時所提供的個人資料，如會員註冊資訊（例如：姓名、電子郵件地址、聯絡方式等）。這些資料將用於以下目的：
        提供您所需的服務與支援、改善本應用程式的服務品質、進行使用者行為的分析與統計、向您傳遞與本應用程式相關的更新和通知。
      </Typography>
      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        資料分享
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        我們不會將您的個人資料出售或租賃給第三方。但在以下情況下，我們可能會與第三方分享您的資料：獲得您的明確同意後、當法律要求或為了遵守法規義務時、為了保護本公司或他人的權利、財產或安全時。
      </Typography>
      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        資料安全
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        我們採取合理的技術與組織措施，以保護您的個人資料免於遺失、盜用或未經授權的存取。
      </Typography>
      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        資料保留與刪除
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        您的個人資料將被保存至達成蒐集目的為止，或法律要求的最長保存期限內。一旦資料不再需要，我們將安全地刪除或匿名化處理。
      </Typography>
      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        使用者的權利
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        您有權查閱、更正或要求刪除我們所持有的您的個人資料。如果您希望行使這些權利，請透過以下聯絡方式與我們聯繫。
      </Typography>
      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        隱私權問題聯絡方式
      </Typography>
      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        政策變更
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        我們保留隨時修改本隱私權政策的權利。任何修改將會立即生效，並將透過本應用程式通知您。請定期查閱本隱私權政策，以確保您瞭解我們如何保護您的資料。
      </Typography>
      <Box sx={{ height: 20 }} />
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 'bold' }}>
        連結與存取
      </Typography>
      <Box sx={{ height: 10 }} />
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        本隱私權政策位於<a href={"https://terrago.ai/privacy-policy"}>https://terrago.ai/privacy-policy</a>，您可以在任何標準瀏覽器中直接閱讀此政策，無需任何外掛或特殊處理。
      </Typography>
      <Box sx={{ height: 70 }} />
    </Container>
  );
}

export default PrivacyPolicy;
