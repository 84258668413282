import React from 'react';
import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
import {Box, Container, Grid, Typography} from '@mui/material';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import Home from "./pages/Home";
import About from "./pages/About";
import Support from "./pages/Support";

function App() {

  return (
    <Router>
      <Container maxWidth="lg">
        <Link to="/" style={{textDecoration: 'none'}}>
          <Box pt={4}>
            <img src="/image/app-name.png" alt="Dooloaku" height={'40px'}/>
          </Box>
        </Link>
      </Container>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="/terms-of-use" element={<TermsOfUse/>}/>
        <Route path="/support" element={<Support/>}/>
      </Routes>
      <Container>
        <Grid py={1}>
          <Box maxWidth={'sm'} display={'flex'}>
            <Link to="/">
              <Typography fontFamily={'Jetbrains Mono'} mt={4} mb={4} mr={4}>
                Home
              </Typography>
            </Link>
            <Link to="/about">
              <Typography fontFamily={'Jetbrains Mono'} mt={4} mb={4} mr={4}>
                About
              </Typography>
            </Link>
            <Link to="/privacy-policy">
              <Typography fontFamily={'Jetbrains Mono'} mt={4} mb={4} mr={4}>
                Privacy
              </Typography>
            </Link>
            <Link to="/terms-of-use">
              <Typography fontFamily={'Jetbrains Mono'} mt={4} mb={4} mr={4}>
                Term of Service
              </Typography>
            </Link>
            <Link to="/support">
              <Typography fontFamily={'Jetbrains Mono'} mt={4} mb={4} mr={4}>
                Support
              </Typography>
            </Link>
          </Box>
          <Grid item md={4}>
            <Typography fontFamily={'Jetbrains Mono'}>
              © 2024 Terrago.ai
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Router>
  );
}

export default App;
