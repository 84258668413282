import React from 'react';
import {Box, Container, Typography} from '@mui/material';
import {MailOutlined} from "@mui/icons-material";

function About() {

  const _launchMail = () => {
    window.location.href = "mailto:service@terrago.ai";
  };

  return (
    <Container maxWidth="lg" sx={{padding: 2}}>
      <Typography variant="body1" sx={{fontSize: 18}}>
        我們的願景是透過尖端工具革新房地產行業，為房地產行業提供簡化和增強他們工作的能力。
      </Typography>

      <Typography fontFamily={'Jetbrains Mono'} variant="body1" sx={{fontSize: 18}}>
        透過專為房地產專業人士提供的智能助理，能夠整合地理數據、法令知識、房產分析及客戶管理，進而加速工作效率，提高工作品質，最終為客戶提供更好的服務。

        我們相信，房地產行業正在改變，TerraGo 也將持續卓越前行，全力支持全球房地產AI的成功。
      </Typography>
      {/*{*/}
      {/*  [*/}
      {/*    {name: 'Zack', position: 'Founder', company: 'UrbanPlanning. GIS. Mobile App. Web App.'},*/}
      {/*    {name: 'Bill', position: 'Co-Founder', company: 'GIS. LLM Training. Reinforce Learning.'},*/}
      {/*    {name: 'Tim', position: 'Co-Founder', company: 'LLM Training. Reinforce Learning.'},*/}
      {/*    {name: 'Kerry', position: 'Co-Founder', company: 'Cloud Infrastructure. DevOps.'},*/}
      {/*  ].map((item, index) => (*/}
      {/*    <Box key={index} sx={{mt: 2}}>*/}
      {/*      <Typography fontFamily={'Jetbrains Mono'} variant="h6" sx={{fontSize: 20}}>{item.name}</Typography>*/}
      {/*      <Typography fontFamily={'Jetbrains Mono'} variant="body1" sx={{fontSize: 18}}>{item.position}</Typography>*/}
      {/*      <Typography fontFamily={'Jetbrains Mono'} variant="body1" color={'#a6a6a6'}*/}
      {/*                  sx={{fontSize: 18}}>{item.company}</Typography>*/}
      {/*    </Box>*/}
      {/*  ))*/}
      {/*}*/}
      <Box display={'flex'} alignItems={'center'} mt={6}/>
      <Typography fontFamily={'Jetbrains Mono'}
                  sx={{fontSize: 18}}> 我們正在積極招募！歡迎幫助我們擴展、探索和建立技術基礎設施。如果您有意願提供協助，請給我們發送電子郵件。我們期待您。
      </Typography>
      <Box sx={{height: 70}}/>
    </Container>
  );
}

export default About;
